.LoadingContainer-title {
    font-weight: bold;
}

.LoadingContainer-vertically-centered {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 25px 0;
    width: 100%;
}