.StaticNavbarItem-left {
    float: left;
}

.StaticNavbarItem-right {
    float: right;
}

.StaticNavbarItem-icon {
    display: none;
    width: 32px;
    height: 32px;
    margin: 0 auto;
}

.StaticNavbarItem div {
    font-weight: bold;
    line-height: 25px;
    display: block;
    color: #e6fff2;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    border-radius: 5px;
}

.StaticNavbarItem-active {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    color: white;
    background-color: #004d26;
}

@media only screen and (max-width: 720px) {
    .StaticNavbarItem {
        display: block;
        width: 100%;
    }
    .StaticNavbarItem-icon {
        display: block;
    }
    .StaticNavbarItem-text {
        display: none;
    }
    .StaticNavbarItem-left {
        clear: both;
    }
    .StaticNavbarItem-right {
        float: left;
        clear: both;
    }
    .StaticNavbarItem div {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (min-width: 721px) {
    .StaticNavbarItem-right {
        clear: none;
        float: right;
    }
    .StaticNavbarItem-left {
        clear: none;
    }
    .StaticNavbarItem div {
        padding: 14px 16px;
        height: 25px;
    }
    .StaticNavbarItem div:hover {
        transition: 0.3s;
        -webkit-transition: 0.3s;
        color: white;
        background-color: #004d26;
    }
}