.InputRating {
    margin: 20px;
}

.InputRating-button {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    margin-right: 5px;
    margin-left: 5px;
}

.InputRating-grayscale-star {
    -webkit-filter: brightness(300%) grayscale(100%);
    filter: brightness(300%) grayscale(100%);
}

@media only screen and (min-width: 720px) {
    .InputRating-button:hover {
        opacity: 0.7;
    }
}