.MediaContainer-intro {
    font-size: large;
}

.MediaContainer-slideshow {
    margin: 0 auto;
    position: relative;
}

.MediaContainer-slideItem {
    max-height: 50vh;
    max-width: 100vw;
    display: block;
    margin: 0 auto;
}

.MediaContainer-slideItemHidden {
    display: none;
}

.MediaContainer-vertically-centered {
    margin: 10px 0;
    width: 100%;
}

.MediaContainer-navigator {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    color: white !important;
    text-align: center !important;
    font-size: 36px !important;
    font-weight: bold;
    padding: 5px;
    margin: 10px;
    width: 50px;
    border-radius: 10px;
    background-color: #007E40;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.MediaContainer-navigatorDisabled {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    color: white !important;
    text-align: center !important;
    font-size: 36px !important;
    font-weight: bold;
    padding: 5px;
    margin: 10px;
    width: 50px;
    border-radius: 10px;
    background-color: #343434;
}

@media only screen and (max-width: 720px) {
    .MediaContainer-slideItem {
        max-height: 30vh;
        max-width: 100%;
    }
    .MediaContainer-navigator {
        width: 35px;
        padding: 4px;
    }
    .MediaContainer-navigatorDisabled {
        width: 35px;
        padding: 4px;
    }
}

@media only screen and (min-width: 720px) {
    .MediaContainer-navigator:hover {
        opacity: 0.8;
    }
}