.StaticNavbar {
    width: 50px;
    position: fixed;
    margin: 0;
    margin-bottom: 25px;
    z-index: 9999;
    top: 0;
    overflow: hidden;
    padding: 0;
    background-color: #007E40;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    list-style-type: none;
    box-shadow: 0px 0px 5px 0px #000;
}

@media only screen and (max-width: 720px) {
    .StaticNavbar {
        height: 100%;
        padding-top: 40px;
    }
}

@media only screen and (min-width: 721px) {
    .StaticNavbar {
        width: 100% !important;
        height: inherit !important;
        padding: 0 !important;
    }
}