.Dropdown-button {
    background-color: #007E40;
    color: white;
    padding: 14px 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 250px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    text-align: left;
}

.Dropdown-button-pressed {
    background-color: #004d26;
}

.Dropdown {
    position: relative;
    display: inline-block;
    margin: 5px;
}

.Dropdown-content {
    position: absolute;
    background-color: #eee;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.Dropdown-content button {
    color: black;
    border: none;
    background-color: white;
    padding: 12px 16px;
    text-align: left;
    text-decoration: none;
    display: block;
    cursor: pointer;
    width: 250px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.Dropdown-active {
    color: white !important;
    background-color: #007E40 !important;
}

@media only screen and (min-width: 721px) {
    .Dropdown-button:hover {
        background-color: #004d26;
    }
    .Dropdown-content button:hover {
        background-color: #ddd;
        color: black !important;
    }
}