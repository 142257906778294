#toast {
    opacity: 0;
    width: calc(100% - 40px);
    margin: 20px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 16px;
    position: fixed;
    box-sizing: border-box;
    z-index: 1;
    bottom: 0;
    font-size: 17px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    transform: translateY(75px);
    -webkit-transform: translateY(75px);
    cursor: pointer;
}

#toast.success {
    background-color: rgb(60, 110, 58);
}

#toast.error {
    background-color: rgb(116, 60, 65);
}

#toast.warning {
    background-color: rgb(91, 95, 49);
}

#toast.neutral {
    background-color: #333;
}

#toast.show {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 0.8;
}

#toast.left {
    text-align: left;
}

#toast.center {
    text-align: center;
}

#toast.bold {
    font-weight: bold;
}

@media only screen and (max-width: 720px) {
    #toast {
        width: calc(100% - 90px);
        margin-left: 70px;
    }
}

@media only screen and (min-width: 720px) {
    #toast.show:hover {
        opacity: 0.5;
    }
}