.WeatherWidget-container {
    width: 100%;
    text-align: center;
    display: block;
    margin: 5px auto;
}

.WeatherWidget-text {
    font-size: 17px;
    text-align: center;
    margin: 2px auto;
}

.WeatherWidget-dateTime {
    font-size: 17px;
    text-align: center;
    margin: 2px 5px;
    display: inline-block;
}

.WeatherWidget-temperature {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    color: #007E40;
    margin: 5px auto;
}

.WeatherWidget-image {
    width: 50px;
    height: 50px;
}

.WeatherWidget-dateNavigator {
    margin-bottom: 5px;
}

.WeatherWidget-button {
    border-radius: 3px;
    margin: 0 5px;
    text-align: center;
    text-anchor: middle;
    background: #007E40;
    color: white;
    padding: 2px;
    width: 35px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.WeatherWidget-buttonDisabled {
    background: #777;
}

.WeatherWidget-buttonContainer {
    margin: 2px 0;
}

.WeatherWidget-hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #fff, #333, #fff);
}

@media only screen and (max-width: 720px) {
    .WeatherWidget-container {
        width: 100%;
    }
}

@media only screen and (min-width: 721px) {
    .WeatherWidget-button:hover {
        opacity: 0.5;
    }
    .WeatherWidget-buttonDisabled:hover {
        opacity: 1;
    }
}