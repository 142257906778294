.FormField {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
}

.FormField-label {
    display: block;
    box-sizing: border-box;
    padding: 5px;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    transform: translateY(35px);
    cursor: text;
    pointer-events: none;
}

.FormField-labelVisible {
    transform: translateY(0);
    opacity: 1;
    cursor: auto;
}

.FormField-input {
    text-align: center;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    box-sizing: border-box;
    padding: 12px 20px;
    display: block;
    width: 250px;
    margin: 0 auto;
    border: none;
    border: 2px solid rgb(29, 29, 29);
    background-color: white;
    border-radius: 4px;
    outline: none;
}

.FormField-input:focus {
    border-color: #007E40;
}

.FormField-input:valid {
    background: #e6fff2;
}