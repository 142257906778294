.Container {
    margin-top: 53px;
    margin-bottom: 20px;
}

.Container-textAlignCenter {
    text-align: center;
}

@media only screen and (max-width: 720px) {
    .Container {
        margin-top: 0;
        margin-left: 50px;
    }
}