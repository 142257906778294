.Spinner {
    margin: 25px auto;
    text-align: center;
}

.Spinner>div {
    width: 20px;
    height: 20px;
    margin: 0 5px;
    background-color: #007E40;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bounce 1.4s infinite ease-in-out both;
    animation: bounce 1.4s infinite ease-in-out both;
}

.Spinner .Spinner-bounce1 {
    -webkit-animation-delay: -0.20s;
    animation-delay: -0.20s;
}

.Spinner .Spinner-bounce2 {
    -webkit-animation-delay: -0.10s;
    animation-delay: -0.10s;
}

@-webkit-keyframes bounce {
    0%, 100% {
        -webkit-transform: scale(0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes bounce {
    0%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}