.DetailsContainer-navigator {
    width: max-content;
    margin: 0 auto;
}

.DetailsContainer-ratingContainer {
    background: url("./star-rating-sprite.png") repeat-x;
    font-size: 0;
    height: 21px;
    line-height: 0;
    overflow: hidden;
    width: 110px;
    margin: 0 20px;
}

.DetailsContainer-rating {
    background: url("./star-rating-sprite.png") repeat-x;
    background-position: 0 100%;
    float: left;
    height: 21px;
    display: block;
}

.DetailsContainer-label, .DetailsContainer-value {
    padding: 7px 20px;
}

.DetailsContainer-buttonImage {
    width: 64px;
    height: 64px;
    margin: 0 10px;
}

.DetailsContainer-buttonLink {
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.DetailsContainer-button {
    background-color: white;
    color: black;
    border: 2px solid #007E40;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    margin: 10px;
    -webkit-transition-duration: 0.2s;
    /* Safari */
    transition-duration: 0.2s;
    cursor: pointer;
    font-weight: 700;
}

.DetailsContainer-buttonRow {
    margin-top: 25px;
    margin: 25px 10px 0 10px;
}

.DetailsContainer-bannerImage {
    margin-top: 10px;
    width: 50%;
    margin: 0 auto;
    display: block;
}

.DetailsContainer-clickableBannerImage {
    cursor: pointer;
}

.DetailsContainer-bannerHandler {
    display: block;
    background-color: white;
    color: black;
    border: 2px solid #007E40;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    margin: 2px auto;
    -webkit-transition-duration: 0.2s;
    /* Safari */
    transition-duration: 0.2s;
    cursor: pointer;
    font-weight: 700;
}

.DetailsContainer-banner {
    margin-top: 10px;
    width: 100%;
}

@media only screen and (max-width: 720px) {
    .DetailsContainer-bannerImage, .DetailsContainer-clickableBannerImage {
        width: 100%;
    }
}

@media only screen and (min-width: 720px) {
    .DetailsContainer-bannerHandler:hover, .DetailsContainer-button:hover {
        background-color: #007E40;
        color: white;
    }
    .DetailsContainer-buttonLink:hover {
        opacity: 0.8;
    }
}