.PageNotFoundContainer {
    margin-top: 53px;
    margin-bottom: 20px;
    text-align: center;
}

.PageNotFoundContainer-header {
    background-color: rgb(240, 240, 240);
    height: 70px;
    padding: 20px;
    color: rgb(37, 37, 37);
}

.PageNotFoundContainer-title {
    font-size: 1.5em;
}

.PageNotFoundContainer-intro {
    font-size: large;
}

.PageNotFoundContainer-vertically-centered {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
}

@media only screen and (max-width: 720px) {
    .PageNotFoundContainer {
        margin-top: 0;
        margin-left: 50px;
    }
    .PageNotFoundContainer-vertically-centered {
        width: calc(100% - 50px);
    }
}