.Header-logo {
    height: 64px;
    display: block;
    margin: 0 auto;
}

.Header-with-logo {
    background-color: #ddd;
    padding: 20px;
    color: rgb(37, 37, 37);
    text-align: center;
}

.Header {
    background-color: #ddd;
    padding: 10px;
    color: rgb(37, 37, 37);
    text-align: center;
}

.Header-titleHome {
    font-size: 1.5em;
    display: inline-block;
    margin: 10px 0;
}

.Header-titleRegular {
    font-size: 1.3em;
    display: inline-block;
    margin: 10px 0;
}

.Header-link {
    display: inline-block;
    background-color: transparent;
    outline: none;
    background-repeat: no-repeat;
    border: none;
    z-index: 99;
    font-size: 1.3em;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #007E40;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    margin: 10px;
    position: absolute;
}

.Header-left {
    left: 15px;
}

.Header-right {
    right: 15px;
}

.Header-buttonsPadding {
    margin-left: 60px;
    margin-right: 60px;
}

.Header-link :hover {
    opacity: 0.8;
}

@media only screen and (max-width: 720px) {
    .Header-left {
        left: 65px;
    }
}