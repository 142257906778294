.ListContainer-intro {
    font-size: large;
}

.ListContainer-children {
    margin-bottom: 50px;
}

.ListContainer-bg-image {
    max-width: 33.33%;
    position: fixed;
    left: 16.66%;
    padding-right: 10px;
    padding-left: 10px;
    top: calc(50% + 80px);
    max-height: 75vh;
    transform: translate(-50%, -50%);
}

.ListContainer-items {
    width: 75%;
    margin: 20px auto;
}

.ListContainer-items-with-image {
    width: auto;
    margin: 20px 5% 20px calc(33.33% + 5%);
}

@media only screen and (max-width: 720px) {
    .ListContainer-bg-image {
        max-width: calc(95vw - 50px);
        max-height: 80vh;
        position: relative;
        left: auto;
        top: auto;
        margin: 0 auto;
        transform: none;
        display: block;
    }
    .ListContainer-items-with-image {
        width: 95%;
        margin: 20px auto;
    }

    .ListContainer-items {
        width: 95%;
        margin: 20px auto;
    }
}