.TabContainer {
    margin-bottom: 25px;
}

.TabContainer-return {
    position: fixed;
    z-index: 10;
    left: 15px;
    top: 23px;
    cursor: pointer;
}

#TabContainer-tab {
    z-index: 99;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 25px 0;
    border-top-style: solid;
    border-width: 2px;
    border-color: #007E40;
    background-color: white;
}

.TabContainer-tabItem {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    padding: 0 5%;
}

.TabContainer-tabItem:hover, .TabContainer-activeTabItem:hover {
    opacity: 0.7;
}

.TabContainer-activeTabItem {
    font-weight: bold;
    color: #007E40;
}

.TabContainer-content {
    position: absolute;
    overflow-y: scroll;
}

@media only screen and (max-width: 720px) {
    .TabContainer {
        margin-bottom: 0;
    }
    #TabContainer-tab {
        position: fixed;
        left: 50px;
        padding: 10px;
    }
    .TabContainer-tabItem {
        padding: 4px 0;
        width: 100% !important;
    }
}