.ListItem {
    font-size: 11px;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    margin: 15px 0;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    min-height: 74px;
}

.ListItem-priority {
    background: linear-gradient(to bottom right, #7e7b00, #c4c000, #7e7b00) !important;
}

.ListItem-disabled {
    opacity: 0.5;
}

.ListItem:hover {
    opacity: 0.9;
}

.ListItem-textbox {
    float: left;
}

.ListItem-logo {
    height: 64px;
    width: 64px;
    float: left;
    margin: 5px;
    border-radius: 7px;
}

.ListItem-image {
    height: 64px;
    width: 64px;
    float: right;
    margin: 5px;
    border-radius: 7px;
}

.ListItem-text {
    color: #e6fff2;
    margin: 5px 10px;
    clear: both;
    text-align: left;
}

.ListItem-bold {
    font-weight: bold;
}

@media only screen and (max-width: 720px) {
    .ListItem {
        margin: 10px 5%;
    }
    .ListItem-image {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .ListItem {
        min-height: 42px;
    }
    .ListItem-textbox {
        font-size: 9px;
    }
    .ListItem-logo {
        height: 32px;
        width: 32px;
    }
    .ListItem-text {
        margin: 2px 5px;
    }
    .ListItem-image {
        display: none;
    }
}