.HomeContainer-intro {
    font-size: large;
}

.HomeContainer-banner {
    width: 35%;
    cursor: pointer;
}

.HomeContainer-badge {
    cursor: pointer;
}

.HomeContainer-languageButton {
    cursor: pointer;
    border: none;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    background-color: transparent;
}

.HomeContainer-language {
    width: 32px;
    height: 32px;
}

.HomeContainer-vertically-centered {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 25px 0;
    width: 100%;
}

.HomeContainer-children {
    margin-bottom: 25px;
}

.HomeContainer-hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 77, 38, 0), rgba(0, 77, 38, 0.75), rgba(0, 77, 38, 0));
}

.HomeContainer-languageActive {
    border: 2px solid #333333;
    border-radius: 50%;
}

@media only screen and (max-width: 720px) {
    .HomeContainer-banner {
        width: 90%;
    }
    .HomeContainer-vertically-centered {
        width: calc(100% - 50px);
    }
    .HomeContainer-language {
        width: 64px;
        height: 64px;
    }
}

@media only screen and (min-width: 721px) {
    .HomeContainer-languageButton:hover {
        opacity: 0.8;
    }
}