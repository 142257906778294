.leaflet-container {
    height: 100%;
    width: 100%;
}

.leaflet-map-marker-active {
    width: 20px;
    height: 20px;
    border-radius: 50% 50% 50% 0;
    background: black;
    position: absolute;
    transform: rotate(-45deg);
    left: 83%;
    top: 75%;
    margin: -20px 0 0 -20px;
}

.leaflet-map-marker-inactive {
    width: 20px;
    height: 20px;
    border-radius: 50% 50% 50% 0;
    background: gray;
    position: absolute;
    transform: rotate(-45deg);
    left: 83%;
    top: 75%;
    margin: -20px 0 0 -20px;
}

.MapView {
    position: relative;
    width: 100%;
    height: calc(100vh - 53px);
}

.MapView-spinnerContainer {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 10px;
}

.MapView-link {
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    outline: none;
    background-repeat: no-repeat;
    border: none;
    padding: 0;
}

@media only screen and (max-width: 720px) {
    .MapView {
        height: 100vh;
    }
}