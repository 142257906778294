.Maintenance-Main {
    position: fixed;
    z-index: 99999;
    right: 0;
    top: 53px;
    opacity: 0.9;
    width: 90px;
    height: 90px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    pointer-events: none;
}

.Maintenance-Main:hover {
    opacity: 0.1;
}

@media only screen and (max-width: 720px) {
    .Maintenance-Main {
        opacity: 0.1;
        top: 0;
    }
}