.TabbedListContainer-items {
    width: 75%;
    margin: 20px auto;
}

.TabbedListContainer-intro {
    font-size: large;
}

.TabbedListContainer-children {
    margin-bottom: 50px;
}

@media only screen and (max-width: 720px) {
    .TabbedListContainer-items {
        width: 95%;
        margin: 10px auto;
    }
}