.Icon {
    display: inline-block;
    margin: 0 10px;
    width: 192px;
}

.Icon-image {
    height: 128px;
    width: 128px;
}

.Icon-pointer {
    cursor: pointer;
}

.Icon-title {
    font-weight: bold;
    font-size: 14px;
}

@media only screen and (max-width: 720px) {
    .Icon {
        width: 96px;
    }
    .Icon-image {
        height: 64px;
        width: 64px;
    }
}